.rodape{
	background: #1f3485;
	color: #FFF;
	padding-top: 70px;

	.btn,
	.form-control{
		border-radius: 0;
	}

	form{
		font-family: 'robotolight_italic';
	}
}

.title-footer{
	font-size: 30px;
	color: #1cadcf;
	margin-bottom: 25px;

	span{
		color: #FFF;
		font-size: toEm(40,30);
	}
}

.creditos{
	padding-bottom: 10px;
	background: #000;
	font-size: 12px;

	&:before{
		content:'';
		display: block;
		border-top:1px solid #1f3485;
		border-bottom:2px solid #1f3485;
		padding-top:1px;
		background: #FFF;
		margin-bottom: 10px;
	}

	.col-auto{
		max-width: 100%;
		word-break: break-wall;
	}
}

.dados-footer{
	margin-bottom: 30px;

	.icon-area{
		width: 25px;
	}
}

#mapa-footer{
	padding-bottom: 100%;
}

.detail-mapa{
	&:before,
	&:after{
		content: '';
		width: percentage(268/350);
		display: block;
		margin-right:auto;
		margin-left:auto;
		height: 15px;
		background: #15a1c1;
	}
}