.icon{
	background-image: url('#{$imgs}/sprite.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 107px 79px;
	
	&.envelope-footer{
		width: 15px;
		height: 11px;
		background-position: -71px -64px;
	}
	&.envelope{
		width: 15px;
		height: 11px;
		background-position: -46px -64px;
	}
	&.facebook-curta{
		width: 25px;
		height: 25px;
		background-position: -36px 0px;
	}
	&.map-marker-footer{
		width: 11px;
		height: 15px;
		background-position: -96px 0px;
	}
	&.map-marker-topo{
		width: 11px;
		height: 15px;
		background-position: -25px -64px;
	}
	&.relogio-footer{
		width: 16px;
		height: 16px;
		background-position: -28px -36px;
	}
	&.telefone-footer{
		width: 15px;
		height: 15px;
		background-position: -71px 0px;
	}
	&.telefone-topo{
		width: 15px;
		height: 15px;
		background-position: -71px -25px;
	}
	&.whatsap{
		width: 18px;
		height: 18px;
		background-position: 0px -36px;
	}
	&.whatsapp-footer{
		width: 15px;
		height: 15px;
		background-position: 0px -64px;
	}
	&.whatsapp-send-message{
		width: 26px;
		height: 26px;
		background-position: 0px 0px;
	}
}


