.btn-expanded{
	padding: .375rem 1.5rem;

	&.btn-sm{
		padding: .25rem 1rem;
	}

	&.btn-lg{
		padding: .5rem 2rem;
	}
}

.btn-pill{
	border-radius: 1.5rem;

	&.btn-sm{
		border-radius: 1rem;
	}

	&.btn-lg{
		border-radius: 2rem;
	}
}