#banner{
	width: 1920px;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;


	a:hover{
		text-decoration: none;
	}

	.banner-image{
		display: flex;
		min-height:700px;
		padding: 60px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		color: #FFF;
		text-align: center;

		@include media-breakpoint-down(sm){
			padding-left: 15px;
			padding-right: 15px;
		}

		.content-banner{
			width: 884px;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			padding: 15px;
			border-top:6px solid #fff;
			border-bottom:6px solid #fff;
			display: flex;
			align-items: center;
			z-index:2;

			&:before,
			&:after{
				content: "";
				width:calc(100% + 12px);
				height:33.333%;
				border-left:6px solid #FFF;
				border-right:6px solid #FFF;
				left:-6px;
				position: absolute;
				z-index:-1;
			}

			&:before{
				top: -6px;
			}

			&:after{
				bottom: -6px;
			}
		}

		.text-block{
			width: 100%;
		}

		.title-banner{
			font-family: 'algre_sansregular';

			@include media-breakpoint-up(md){
				font-size: 60px;
			}
		}

		.faixa-azul{
			background: rgba(#080f2c, 0.7);
			padding: 10px;
			margin-left: -15px;
			margin-right: -15px;
			font-family: 'algre_sansregular';
			margin-top: 40px;
			margin-bottom: 25px;

			@include media-breakpoint-down(sm){
				font-size: 2rem;
			}
			@include media-breakpoint-up(md){
				font-size: 60px;
			}
		}

		.btn{
			border-radius: 0;
			font-weight: bold;
			font-size: 14px;
		}
	}

	.carousel-inner{
		position: relative;
		z-index:0;
	}

	.controles{
		position: absolute;
		width: 100%;
		height:100%;
		left:0;
		top:0;
		display: flex;
		align-items: center;
		justify-content: center;

		.clearfix{
			width: 1170px;
			max-width: 100%;
			padding-left:15px;
			padding-right:15px;
		}
	}

	.ctrls{
		position: relative;
		z-index: 6;
		display: inline-block;
		padding:0.35rem 0.75rem;
		color: #FFF;
		font-size:30px;

		&:first-child{
			float:left;
		}

		&:last-child{
			float:right;
		}
	}
}

.versiculo-biblico{
	color: #1cadcf;
}

.qm-somos,
.produtos-detaque,
.profissionais{
	h1,
	.h1{
		color: #1cadcf;
		font-size: 30px;
		
		span{
			color: #1c3183;
			font-size: toEm(40,30);
			font-family: 'robotolight_italic';
		}
	}
}

.produtos-detaque{
	h1,
	.h1{
		span{
			color: #FFF;
		}
	}
}

.produtos-detaque{
	padding: 60px 0;
	background-position: center center;
	background-attachment: fixed;
	background-size: cover;
	background-image: url('../images/bg-produtos.jpg');
	color: #FFF;
	background-color: #3d4041;
	text-align: center;
	font-size: 14px;


	.categorias-prods{
		list-style: none;

		li {
			display: inline-block;

			& + li:before{
				content: '';
				display: inline-block;
				width: 0.5em;
				height: 0.5em;
				background: currentcolor;
				border-radius: 100%;
				margin: 0 1em;
			}
		}
	}
}

.produto{
	width: 344px;
	max-width: 100%;
	text-align: center;

	.foto-produto{
		border-radius: 100%;
		overflow: hidden;
		margin-bottom: 10px;
	}

	&.shadow-prod{
		.foto-produto{
			box-shadow:5px 5px 8px rgba(#000,0.4);
			margin-bottom: 20px;
		}
	}

	.nome-produto{
		color: #1f3485;
		margin-bottom: 15px;
		font-family: 'robotoblack';
	}

	.descricao-produto{
		font-size: 14px;
		color: #080f2c;
	}
}

.owl-produtos{
	padding-left: 40px;
	padding-right: 40px;
	position: relative;

	.controls{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		font-size: 30px;

		&.prev{
			left: 0;
		}

		&.next{
			right: 0;
		}
	}
}


.internas{
	padding: 50px 0;
	border-top:1px solid #CCC;
}

.page-header{
	text-align: center;
	color: #080f2c;
	font-style: italic;
	font-size: 40px;
	margin-bottom: 60px;
}

.paginacao{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	font-weight: bold;

	a,
	span{
		display: inline-block;
		padding:0.3rem 0.75rem;
		margin:0.5rem;
		border-radius: 100%;
	}

	a{
		background: #15a1c1;
		color: #1f3485;
	}

	a:hover,
	span{
		background: #1f3485;
		color: #FFF;
		text-decoration: none;
	}
}