.topo{
	position: relative;
	z-index: 999;
}

.faixa-topo{
	padding: 10px 0;
	color: #FFF;
	background: #1f3485;
	text-align: center;

	div[class^="col"]{
		word-break: break-all;
		max-width: 100%;
	}

	a:hover{
		color: #FFF;
	}
}

.mobile-controls{
	display: flex;
	justify-content: space-between;
	min-height: 75px;
	border-bottom:1px solid #CCC;
	width: 100%;
	left:0;
	top:0;


	&.fx{
		position: fixed;
		top: 0;
		background: #FFF;
	}

	.brand{
		padding:5px 15px;
		width: calc(100% - 40px);
		max-width:358px;
		margin-bottom: 0;
		align-self: center;
	}

	.btn{
		width:48px;
		border-radius: 0;
		background: #fff;
		color: #1F3983;

		&:hover{
			color: #FFF;
			background: #1F3983;
		}
	}

	@include media-breakpoint-up(md){
		display: none;
	}
}

.topo-content{

	.menu{
		color: #080f2c;

		ul{
			list-style: none;
			padding-left:0;
			margin-bottom: 0;
		}

		a{
			display: block;
		}
	}

	.menu-cateegorias-header{
		display: flex;

		a{
			flex-grow: 1;
			display: block;
		}

		button{
			border-radius: 0;
			background: #FFF;

			&[aria-expanded="true"] .fa:before{
				content: "\f068";
			}
		}
	}

	@include media-breakpoint-up(md){
		width: map-get($container-max-widths, xl);
	}

	@include media-breakpoint-only(sm){
		width: map-get($container-max-widths, sm);
	}

	@include media-breakpoint-only(md){
		width: map-get($container-max-widths, md);
	}

	@include media-breakpoint-up(md){
		padding-left:15px;
		padding-right:15px;
		margin-left: auto;
		margin-right: auto;

		.brand{
			text-align: center;
			margin: 30px 0;
		}

		.menu > ul{
			display: flex;
			justify-content: center;

			& > li{
				display: flex;
				align-items: center;

				& + li:before{
					content:'';
					display: inline-block;
					width: 4px;
					height:4px;
					margin:0 toEm(20, 14);
					background: currentcolor;
					border-radius: 100%;
				}

				& > a,
				.menu-cateegorias-header a{
					padding: 5px 0;
					border-top:3px solid transparent;
					border-bottom:3px solid transparent;
				}

				&:hover,
				&.active{
					a,
					.menu-cateegorias-header a{
						border-color: #1f3485;
						text-decoration: none;
					}
				}
			}
		}

		.menu-categorias{
			position: relative;

			.btn{
				display: none;
			}

			.subcategoria{
				position: absolute;
				left: 0;
				top: 100%;
				border:1px solid #38383a;
				min-width: 200px;
				background: #FFF;

				a{
					padding:5px 10px;
				}

				li + li{
					border-top: 1px solid #ccc;
				}

				.active a,
				a:hover{
					background: #1f3485;
					color: #FFF;
					text-decoration: none;
				}
			}

			&:hover .subcategoria{
				display: block;
			}
		}
	}

	@include media-breakpoint-down(sm){
		position: fixed;
		left: -250px;
		top: 0;
		position: fixed;
		width: 250px;
		height: 100%;
		background: #FFF;
		overflow: auto;
		transition: left 0.6s linear;
		z-index:999;

		&.active {
			left: 0;
		}

		.brand{
			padding: 40px 15px 20px 15px;
		}

		.menu {
			a{
				display: block;
				padding: 10px 15px;
			}

			& > ul{
				border-bottom: 1px solid #CCC;

				& > li{
					&.active {
						& > a,
						.menu-cateegorias-header a{
							background: #1F3983;
							color: #FFF;
						}
					}

					& + li{
						border-top: 1px solid #CCC;
					}

				}

			}


			.subcategoria{
				border-top:1px solid #CCC;
				background: #EEE;

				li + li {
					border-top: 1px solid rgba(#000,0.2);
				}

				.active a{
					background: lighten(#1F3983, 10%);
					color: #FFF;
				}
			}
		}
	}
}

@include media-breakpoint-down(sm){
	.bg-menu{
		display: none;
		background: rgba(#000,0.8);
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 998;
	}
}
