@font-face {
    font-family: 'robotoregular';
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'algre_sansregular';
    src: url('#{$fonts}/alegre-sans/alegre-sans-webfont.eot');
    src: url('#{$fonts}/alegre-sans/alegre-sans-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/alegre-sans/alegre-sans-webfont.woff2') format('woff2'),
         url('#{$fonts}/alegre-sans/alegre-sans-webfont.woff') format('woff'),
         url('#{$fonts}/alegre-sans/alegre-sans-webfont.ttf') format('truetype'),
         url('#{$fonts}/alegre-sans/alegre-sans-webfont.svg#algre_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoblack';
    src: url('#{$fonts}/roboto_black/roboto-black-webfont.eot');
    src: url('#{$fonts}/roboto_black/roboto-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_black/roboto-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_black/roboto-black-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_black/roboto-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_black/roboto-black-webfont.svg#robotoblack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotolight_italic';
    src: url('#{$fonts}/roboto-light-italic/roboto-lightitalic-webfont.eot');
    src: url('#{$fonts}/roboto-light-italic/roboto-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto-light-italic/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto-light-italic/roboto-lightitalic-webfont.woff') format('woff'),
         url('#{$fonts}/roboto-light-italic/roboto-lightitalic-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto-light-italic/roboto-lightitalic-webfont.svg#robotolight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}